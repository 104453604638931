import React, { FC } from 'react';

export const Spinner: FC = () => {
  return (
    <div className="spinner spinner_animating">
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
      <div className="spinner__blade" />
    </div>
  );
};
